import Header from "../components/Header";
import { useState, useEffect } from "react";
import Loader from "../components/Loader";
import axios from "axios";
import { Link } from "react-router-dom";
import { server, config } from "../env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthStore } from "../stores/AuthStore";

const Deposits = (props) => {
  const { user } = useAuthStore();
  const [deposits, setDeposits] = useState([]);
  const [loader, setLoader] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [amount, setAmount] = useState(0);

  const getDeposits = async () => {
    await axios
      .get(
        `${server}/api/v1/invoices/readAll?page=${page}&limit=${limit}`,
        config
      )
      .then((res) => {
        setDeposits(res.data.invoices);
        setTotalPages(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AddBalance = async (e) => {
    e.preventDefault();

    const data = {
      amount: e.target.amount.value,
      type: e.target.type.value,
    };

    setLoader(<Loader />);
    await axios
      .post(server + "/api/v1/invoices/create-invoice", data, config)
      .then((res) => {
        setLoader("");
        getDeposits();
        console.log(res.data);
        window.location.href = res.data.data.hosted_url;
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
        console.log(err);
      });
  };

  useEffect(() => {
    getDeposits();
  }, [page, limit]);

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Deposits</h3>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row g-gs ">
                  <div className="col-lg-4">
                    <div className="card h-100">
                      <div className="card-header">
                        <h5 className="card-title text-success">
                          Current Balance
                        </h5>
                      </div>
                      <div className="card-body">
                        <div className="d-flex justify-content-center align-items-center h-100">
                          <h1
                            className={
                              user?.balance === 0 ? "text-danger" : "text-white"
                            }
                            style={{ fontSize: "4rem" }}
                          >
                            $ {user?.balance?.toFixed(2) || 0}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="card shadow-sm border-0">
                      <div className="card-header bg-primary text-white text-center">
                        <h4 className="mb-0">Add Balance</h4>
                      </div>

                      <div className="card-body p-4">
                        {/* Current Balance */}
                        <div className="text-center mb-4">
                          <h5 className="text-secondary">
                            Your Current Balance:{" "}
                            <span className="text-primary">
                              $ {user?.balance?.toFixed(2) || 0}
                            </span>
                          </h5>
                        </div>

                        <form onSubmit={AddBalance}>
                          {/* Input fields */}
                          <div className="row g-3">
                            {/* Amount Input */}
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-label fw-bold">
                                  Amount
                                </label>
                                <input
                                  min={1}
                                  type="number"
                                  className="form-control border-primary"
                                  name="amount"
                                  value={amount}
                                  onChange={(e) => setAmount(e.target.value)}
                                  placeholder="Enter amount"
                                  required
                                />
                              </div>
                            </div>
                            {/* Payment Method Dropdown */}
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-label fw-bold">
                                  Payment Method
                                </label>
                                <select
                                  className="form-control border-primary"
                                  name="type"
                                  required
                                >
                                  <option value="crypto">Crypto</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          {/* Deposit Bonus Information */}
                          <div className="alert alert-info text-center small mt-4">
                            <strong>Deposit Bonus:</strong> <br />
                            Deposit above <strong>$200</strong> to get{" "}
                            <strong>2%</strong>, above <strong>$500</strong> to
                            get <strong>4%</strong>, and above{" "}
                            <strong>$1000</strong> to get <strong>7%</strong>{" "}
                            bonus!
                          </div>

                          {/* Submit Button */}
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-primary btn-lg px-4"
                              type="submit"
                            >
                              <i className="ni ni-plus"></i> Add Balance{" "}
                              {loader}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="card h-100 mt-5">
                      <div className="card-header">
                        <div className="card-title">
                          <h5 className="card-title text-center">
                            <span className="text-primary text-center"></span>
                            <span className="text-center">Invoices</span>
                          </h5>
                        </div>
                      </div>

                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Payment method</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {deposits.map((deposit) => (
                                <tr key={deposit._id}>
                                  <td>
                                    {new Date(
                                      deposit.createdAt
                                    ).toLocaleString()}
                                  </td>
                                  <td>
                                    ${deposit.amount}{" "}
                                    {deposit.bonus > 0 && (
                                      <span className="badge bg-soft-success text-success">
                                        +{deposit.bonus}% Bonus
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {deposit.payment_method === "stripe" ? (
                                      <span className="badge bg-soft-primary text-primary">
                                        Stripe
                                      </span>
                                    ) : deposit.payment_method === "paypal" ? (
                                      <span className="badge bg-soft-info text-info">
                                        Paypal
                                      </span>
                                    ) : deposit.payment_method === "cashapp" ? (
                                      <span className="badge bg-soft-warning text-warning">
                                        CashApp
                                      </span>
                                    ) : deposit.payment_method === "crypto" ? (
                                      <span className="badge bg-soft-success text-success">
                                        Crypto
                                      </span>
                                    ) : (
                                      <span className="badge bg-soft-info text-info">
                                        Manual
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {deposit.status === "pending" ? (
                                      <span className="badge bg-soft-warning text-warning">
                                        Pending
                                      </span>
                                    ) : deposit.status === "cancelled" ? (
                                      <span className="badge bg-soft-danger text-danger">
                                        Cancelled
                                      </span>
                                    ) : deposit.status === "paid" ? (
                                      <span className="badge bg-soft-success text-success">
                                        Completed
                                      </span>
                                    ) : (
                                      <span className="badge bg-soft-danger text-danger">
                                        Expired
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                          <nav>
                            <ul className="pagination">
                              <li
                                className={
                                  page === 1
                                    ? "page-item disabled"
                                    : "page-item"
                                }
                              >
                                <Link
                                  className="page-link"
                                  to=""
                                  onClick={(e) => {
                                    setPage(page - 1);
                                  }}
                                >
                                  Prev
                                </Link>
                              </li>

                              <li
                                className="page-item active"
                                aria-current="page"
                              >
                                <Link className="page-link" to="">
                                  {page}{" "}
                                  <span className="sr-only">(current)</span>
                                </Link>
                              </li>

                              <li
                                className={
                                  page === totalPages
                                    ? "page-item disabled"
                                    : "page-item"
                                }
                              >
                                <Link
                                  className="page-link"
                                  to=""
                                  tabIndex={-1}
                                  onClick={(e) => {
                                    setPage(page + 1);
                                  }}
                                >
                                  Next
                                </Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Deposits;
